import React, { useState, forwardRef } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/hu';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import { PickersDay } from '@mui/x-date-pickers/PickersDay';

const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
  const now = dayjs();
  const diff = date.diff(now, "day");
  const bgColor =
    diff < 0 ? null :
    diff < 7 ? "#FF9999" :
    diff < 30 ? "#FFDDAA" :
    diff < 60 ? "#FFFFBB" :
    "#BBFFCC"
  ;
  return (
    <PickersDay
      {...pickersDayProps}
      style={{
        backgroundColor: bgColor,
      }}
    />
  );
};

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const TooltipToggleButton = forwardRef(
  ({ TooltipProps, ...props }, ref) => {
    return (
      <StyledTooltip {...TooltipProps} title={props.title}>
        <ToggleButton ref={ref} {...props} />
      </StyledTooltip>
    );
  }
);

function OrderForm() {
  const [type, setType] = useState("basic");
  const [flexibility, setFlexibility] = useState("strict");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [flexibleStartDate, setFlexibleStartDate] = useState(null);
  const [flexibleEndDate, setFlexibleEndDate] = useState(null);
  return (
    <Container maxWidth="md" style={{
      border: "1px solid black",
    }}>
      <form
        action="/new/order"
        method="post"
      >
        <p>
          Időintervallum típusa:
          &nbsp;&nbsp;

          <ToggleButtonGroup
            color="primary"
            value={flexibility}
            exclusive
            onChange={e => setFlexibility(e.target.value)}
          >
            <TooltipToggleButton value="strict" title="Ez így kötött lesz">Kötött</TooltipToggleButton>
            <TooltipToggleButton value="flexible" title="Ez így flexibilis lesz">Flexibilis</TooltipToggleButton>
          </ToggleButtonGroup>
        </p>

        { flexibility == "strict" ? (
          <p style={{lineHeight:"50px"}}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="hu">
              <DatePicker
                views={['year', 'month', 'day']}
                label="Kezdődátum (év-hónap-nap)"
                inputFormat="YYYY-MM-DD"
                value={startDate}
                minDate={dayjs()}
                maxDate={dayjs().month(dayjs().month() + 18)}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
                renderDay={renderWeekPickerDay}
              />
            </LocalizationProvider>

            &nbsp;
            <span style={{
              fontSize: "20px",
              fontWeight: "bold",
              verticalAlign: "middle",
            }}>-</span>
            &nbsp;

            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="hu">
              <DatePicker
                views={['year', 'month', 'day']}
                label="Záródátum (év-hónap-nap)"
                inputFormat="YYYY-MM-DD"
                value={endDate}
                minDate={dayjs()}
                maxDate={dayjs().month(dayjs().month() + 18)}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
                renderDay={renderWeekPickerDay}
              />
            </LocalizationProvider>
          </p>
        ) : (
        <p style={{lineHeight:"50px"}}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="hu">
            <DatePicker
              views={['year', 'month']}
              label="Leghamarabb (év-hónap)"
              inputFormat="YYYY-MM"
              minDate={dayjs()}
              maxDate={dayjs().month(dayjs().month() + 18)}
              value={flexibleStartDate}
              onChange={(newValue) => {
                setFlexibleStartDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} helperText={null} />}
            />
          </LocalizationProvider>

          &nbsp;
          <span style={{
            fontSize: "20px",
            fontWeight: "bold",
            verticalAlign: "middle",
          }}>-</span>
          &nbsp;

          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="hu">
            <DatePicker
              views={['year', 'month']}
              label="Legkésőbb (év-hónap)"
              inputFormat="YYYY-MM"
              minDate={dayjs()}
              maxDate={dayjs().month(dayjs().month() + 18)}
              value={flexibleEndDate}
              onChange={(newValue) => {
                setFlexibleEndDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} helperText={null} />}
            />
          </LocalizationProvider>

          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          kb.
          &nbsp;

          <FormControl>
            <TextField
              label="napok"
              helperText="(max. 90)"
              name="days"
              type="number"
              inputProps={{ min: 1, max: 90, maxLength: 2 }}
              style={{width:"100px"}}
            />
          </FormControl>

          &nbsp;
          napra

        </p>

        )}

        <Grid container spacing={10} rowSpacing={1} component="div">
          <Grid xs={12} md={6}>

            <p>
              <FormControl fullWidth>
                <TextField name="phone" label="Telefon" required />
              </FormControl>
            </p>

            <p>
              <FormControl fullWidth>
                <TextField name="email" label="E-mail" type="email" />
              </FormControl>
            </p>
            
          </Grid>
          <Grid xs={12} md={6}>
            <p>
              <FormControl fullWidth>
                <TextField name="email" label="E-mail" type="email" />
              </FormControl>
            </p>
            
            <p>
              <FormControl variant="filled" fullWidth>
                <TextField
                  select
                  name="xxx"
                  label="Válassz"
                  defaultValue={1}
                >
                  {[...Array(8).keys()].map(i => (
                    <MenuItem key={i + 1} value={i + 1}>{"Válasz " + (i + 1)}</MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </p>
          </Grid>
        </Grid>

        <p>
          <FormControl fullWidth>
            <TextField name="name" label="Név" required />
          </FormControl>
        </p>

        <p>
          <FormControl fullWidth>
            <TextField
              name="comment"
              label="Megjegyzések"
              multiline
              minRows={3}
              maxRows={10}
            />
          </FormControl>
        </p>

        <p style={{textAlign: "center"}}>
          <Button type="submit" variant="contained" color="info">
            Rajta!
          </Button>
        </p>
      </form>

    </Container>
  );
}

export default OrderForm;
